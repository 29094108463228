import React, { useState }  from 'react';
import { Title, LinkRef } from "../styles";
import styled from 'styled-components';

const ResearchContainer = styled.div`
  margin: 0 auto;
  font-weight: medium;
  font-size: 15px;
  color: #333;
  line-height: 1.2;
  margin-bottom: 30px;
`;

const PaperContainer = styled.article`
  margin-bottom: 20px;
`;

export const PaperTitle = styled.a`
  margin: 0px;
  color: #333;
  font-size: 15px;
  transition: color 0.3 ease-in;
  text-decoration: none;
  padding-bottom: 5px;
  line-height: 1;
  display: inline-block;
  &:hover {
    color: #3a75c4;
  }
`;

const LinkContainer = styled.div`
  margin-bottom: 10px;
`;

const ContentContainer = styled.div`
  margin-top: 10px;
  display: ${props => props.visible ? 'block' : 'none'};
`;

const BibText = styled.pre`
  font-size: 13px;
`;

const Paper = ({ 
  title, 
  authors, 
  conference, 
  abstract, 
  bibtex, 
  pdfUrl, 
  codeLink, 
  slides // Now accepts an object with different slide formats
}) => {
  const [showAbstract, setShowAbstract] = useState(false);
  const [showBibtex, setShowBibtex] = useState(false);

  const toggleSection = (section) => {
    if (section === 'abstract') {
      setShowAbstract(!showAbstract);
      setShowBibtex(false);
    } else {
      setShowBibtex(!showBibtex);
      setShowAbstract(false);
    }
  };

  // Function to render slide links
  const renderSlideLinks = () => {
    if (!slides) return null;
    
    return (
      <>
        {slides.pdf && <span>&#160;〳</span>}
        {slides.pdf && (
          <LinkRef href={slides.pdf} target="_blank">Slides</LinkRef>
        )}
      </>
    );
  };

  return (
    <PaperContainer>
      <PaperTitle href={pdfUrl} target="_blank" dangerouslySetInnerHTML={{ __html: title }}></PaperTitle><br />
      <span dangerouslySetInnerHTML={{ __html: authors }} /><br />
      {conference}
      <LinkContainer>
        <LinkRef onClick={() => toggleSection('abstract')}>Abstract</LinkRef>&#160;〳
        <LinkRef onClick={() => toggleSection('bibtex')}>Bibtex</LinkRef>&#160;〳
        {codeLink && <><LinkRef href={codeLink} target="_blank">Code</LinkRef></>}
        {renderSlideLinks()}
      </LinkContainer>
      <ContentContainer visible={showAbstract}>{abstract}</ContentContainer>
      <ContentContainer visible={showBibtex}>
        <BibText>{bibtex}</BibText>
      </ContentContainer>
    </PaperContainer>
  );
};

const Research = () => {
  const papers = [
    {
      title: "<b>Cancer-Net PCa-Seg: Benchmarking Deep Learning Models for Prostate Cancer Segmentation Using Synthetic Correlated Diffusion Imaging</b>",
      authors: "<b>Jarett Dewbury</b>, Chi-en Amy Tai, Alexander Wong",
      conference: "AAAI 9th International Workshop on Health Intelligence, 2025",
      abstract: "We present a comprehensive evaluation of deep learning approaches for prostate cancer (PCa) lesion segmentation utilizing synthetic correlated diffusion imaging (CDIs), a novel MRI modality. Given PCa's position as the leading cancer diagnosis among U.S. men, affecting approximately 300,000 individuals and causing 35,000 deaths in 2024, improving diagnostic accuracy is crucial. While traditional screening methods like PSA testing and conventional MRI have been valuable, they face notable limitations. Our study examines five state-of-the-art deep learning architectures—U-Net, SegResNet, Swin UNETR, Attention U-Net, and LightM-UNet—applied to a substantial dataset of 200 CDIs patient scans. Results demonstrate that SegResNet achieves optimal segmentation performance with a Dice-Sorensen coefficient (DSC) of 76.68±0.8, while Attention U-Net provides an efficient alternative with a DSC of 74.82±2.0. These findings suggest that deep learning-based segmentation of CDIs represents a promising avenue for enhancing PCa diagnosis and treatment planning in clinical settings.",
      bibtex: `@misc{
      dewbury2025cancernetpcasegbenchmarkingdeep,
      title={Cancer-Net PCa-Seg: Benchmarking Deep Learning Models for Prostate Cancer Segmentation Using Synthetic Correlated Diffusion Imaging}, 
      author={Jarett Dewbury and Chi-en Amy Tai and Alexander Wong},
      year={2025},
      eprint={2501.09185},
      archivePrefix={arXiv},
      primaryClass={eess.IV},
      url={https://arxiv.org/abs/2501.09185}, 
    }`,
      pdfUrl: "https://arxiv.org/pdf/2501.09185",
      codeLink: "https://github.com/Jdewbury/Cancer-Net-PCa-Seg"
    },
    {
      title: "<b>Sharpness-Aware Minimization (SAM) Improves Classification Accuracy of Bacterial Raman Spectral Data Enabling Portable Diagnostics</b>",
      authors: "Kaitlin Zareno*, <b>Jarett Dewbury*</b>, Siamak Sorooshyari, Hossein Mobahi, Loza F. Tadesse",
      conference: "ICLR 5th Workshop on Practical ML for Low Resource Settings, 2024 (Oral)",
      abstract: "We address the critical issue of antimicrobial resistance (AMR), projected to cause 10 million deaths annually by 2050, especially affecting resource-limited regions. Utilizing Raman spectroscopy, a novel diagnostic approach for rapid and portable antibiotic resistance testing, we overcome limitations in current algorithms that struggle with generalization on limited datasets and require complex pre-processing. By employing Sharpness-Aware Minimization (SAM), we enhance model generalization and achieve up to a 10.5% accuracy improvement on individual data splits and a 2.7% increase in average accuracy across all splits compared to the Adam optimizer. These results demonstrate SAM's potential to advance AI-powered Raman spectroscopy tools for effective AMR detection.",
      bibtex: `@inproceedings{
      zareno2024sharpnessaware,
      title={Sharpness-Aware Minimization ({SAM}) Improves Classification Accuracy of Bacterial Raman Spectral Data Enabling Portable Diagnostics},
      author={Kaitlin Zareno and Jarett Dewbury and Siamak Sorooshyari and Hossein Mobahi and Loza F. Tadesse},
      booktitle={5th Workshop on practical ML for limited/low resource settings},
      year={2024},
      url={https://openreview.net/forum?id=k6FDRRRddZ}
    }`,
      pdfUrl: "https://openreview.net/pdf?id=k6FDRRRddZ",
      codeLink: "https://github.com/Tadesse-Lab/SAM-Raman-Diagnostics",
      slides: {
        pdf: "/slides/SPIE_2025_SAM_Raman_Oral_Talk.pdf"
      }
    }
  ];

  return (
    <ResearchContainer>
      <section id="research">
        <Title>Research</Title>
        {papers.map((paper, index) => (
          <Paper key={index} {...paper} />
        ))}
      </section>
      <p>* Equal Contribution</p>
    </ResearchContainer>
  );
};

export default Research;